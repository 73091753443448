<template lang="pug">
q-page(style="margin-top:-35px;")
  .bg-secondary.text-white.hero1.row(ref="toolbar" v-scroll="scrolled")
    .lt-md.hero2a
    .col
      .row
        .col-12
          Breadcrumbs(:title="$t('lead.trainingmaterials')")
      .row.items-center.full-height
        .col-9.col-md-12.q-pb-xl
          div().q-pb-xl.q-ma-dynamic
            h1 {{$t('lead.trainingtitle')}}
            .text-body1 {{ $t('become-a-certified-ifrc-network-innovation-lead-and-help-young-people-realise-their-limitless-innovation-potential') }}
    .col-6.gt-sm
      .hero2
  
  div(style="height:50px;" v-show="snap")
  //- q-toolbar(:class="{fixme:snap}").bg-primary.q-pa-none
  //-   .scroll().hide-scrollbar.q-pa-dynamic
  //-     .row.justify-left.no-wrap
  //-       .col-auto
  //-         q-btn(flat dark color="white" no-caps to="#about") {{ $t('about') }}
  //-       .col-auto
  //-         q-btn(flat dark color="white" no-caps to="#details") {{ $t('program-details') }}
  //-       .col-auto
  //-         q-btn(flat dark color="white" no-caps to="#modules") {{ $t('modules') }}
  //-       .col-auto
  //-         q-btn(flat dark color="white" no-caps to="#outcomes") {{ $t('outcomes') }}
  //-       .col-auto(v-if="isOpen")
  //-         q-btn(flat dark color="white" no-caps to="#apply") {{ $t('apply') }}
  //-       .col-auto
  //-         q-btn(flat dark color="white" no-caps to="#faq") {{ $t('faqs') }}
  

  .bg-white(id="about")

    .row.items-center.full-height.q-my-xl
      .col-md-9.col-12.q-pb-xl
        .q-ma-dynamic
          .row
            .col-12.col-md-3
              //- q-tabs(v-model="step" vertical align="left")
              //-   q-tab(:name="index" :label="topic.title" no-caps v-for="(topic,index) of messages.lead.topics" content-class="mytab")
                
              q-list
                q-item(no-caps v-for="(topic,index) of messages.lead.topics" clickable selected :active="index==step" @click="step = index" :key="index") {{topic.title}}

            .col-12.col-md
            
              q-tab-panels(v-model="step")
                q-tab-panel(:name="index" v-for="(topic,index) of messages.lead.topics" :key="index")
                  .text-h2.q-mb-md {{topic.title}}
                  .text-body1 {{Object.values(topic.content).join('; ')}}
                  .row.q-mt-lg
                    q-btn.on-left(no-caps color="primary" :icon-right="matNorthEast" :href="getOnePagerUrl(index)" target="_BLANK") Summary and Reference Document
                    q-btn(no-caps color="primary" :icon-right="matNorthEast" :href="getPathUrl(extra.path)" target="_BLANK" v-for="extra of training[index].extras" :key="extra.name" v-if="training[index].extras") {{extra.name}}

                  .row.q-col-gutter-sm.q-mt-lg
                    .col-6(v-for="(video,vi) of training[index].videos")
                      .text-primary.text-bold Video {{vi+1}}
                      q-video(:src="getVideoUrl(video)" :ratio="9/16")
                



  //-         h1 {{ $t('about-limitless-lead-program') }}
  //-         .text-body1 {{ $t('the-ifrc-limitless-lead-innovator-program-is-a-new-professional-development-program-designed-to-help-national-societies-create-and-sustain-their-own-innovation-support-systems-the-program-is-recommended-for-ns-staff-and-volunteers-already-engaged-in-local-innovation-projects-and-are-ready-to-take-on-the-next-step-of-supporting-new-and-upcoming-innovators-in-their-regions-it-provides-opportunities-to-advance-their-innovation-skills-including-professional-development-in-coaching-problem-solving-and-organisational-skills-required-to-become-effective-innovation-leaders') }}
  //- .bg-primary.text-white.q-pb-lg(id="details")
  //-   .row.items-center.full-height
  //-     .col-10.col-sm-12.q-py-xl
  //-       .q-pa-dynamic
  //-         h1 {{ $t('whats-inside-the-program') }}
  //-         .row.q-col-gutter-xl
  //-           .col-12.col-md-6
  //-             .bg-accent.text-black.q-pa-md
  //-               .text-h2 {{ $t('theory') }}
  //-               .text-body1 {{ $t('2-week-intensive-whatsapp-classes-on-advanced-innovation-skills-and-leadership-training') }}
  //-             .text-white.text-body1.q-pr-md
  //-               ul
  //-                 li {{ $t('group-reflections-and-fun-activities') }}
  //-                 li {{ $t('real-life-case-studies') }}
  //-                 li {{ $t('live-chats-with-innovation-experts') }}
  //-                 li {{ $t('access-to-peer-support-on-demand') }}
  //-                 li
  //-                   strong {{ $t('requires-1-hour-of-your-time-each-day-for-live-sessions-individual-activities-and-small-group-discussion') }}

  //-           .col-12.col-md-6
  //-             .bg-accent.text-black.q-pa-md
  //-               .text-h2 {{ $t('practice') }}
  //-               .text-body1 {{ $t('real-world-knowledge-application') }}
  //-             .text-white.text-body1.q-pr-md 
  //-               ul
  //-                 li {{ $t('opportunity-to-practice-what-you-learned-by-facilitating-a-global-innovation-program-via-whatsapp') }}
  //-                 li {{ $t('train-and-coach-youth-innovators-in-your-region-using-a-mix-of-real-time-and-asynchronous-online-activities') }}
  //-                 li {{ $t('apply-your-knowledge-and-build-a-network-of-innovators') }}
  //-                 li
  //-                   strong {{ $t('requires-1-hour-of-your-time-each-day-during-three-8-day-periods-of-innovation-mentoring-between-february-and-april-2024') }}
              
  //- .bg-white(id="modules")
  //-   .q-ma-dynamic
  //-     .row
  //-       .col-12
  //-         .q-py-xl
  //-           .text-h2.q-mb-lg {{ $t('by-joining-limitless-lead-program-you-will-learn-to') }}
  //-           .row.justify-center.q-col-gutter-lg.text-center
  //-             .col-6.col-md
  //-               q-img(src="/img/pic6.webp" :ratio="1" contain style="max-height:200px;")
  //-               .text-body2 {{ $t('apply-the-double-diamond-design-framework-as-a-practical-approach-to-supporting-innovation') }}
  //-             .col-6.col-md
  //-               q-img(src="/img/pic1.webp" :ratio="1" contain style="max-height:200px;")
  //-               .text-body2 {{ $t('implement-and-manage-innovation-support-system-in-your-region') }}
  //-             .col-6.col-md
  //-               q-img(src="/img/pic5.webp" :ratio="1" contain style="max-height:200px;")
  //-               .text-body2 {{ $t('develop-and-sustain-a-local-innovation-network-in-your-region') }}
  //-             .col-6.col-md
  //-               q-img(src="/img/pic7.webp" :ratio="1" contain style="max-height:200px;")
  //-               .text-body2 {{ $t('leverage-cross-cultural-communication-skills-and-techniques-to-support-diverse-innovators') }}
  //-             .col-6.col-md
  //-               q-img(src="/img/pic8.webp" :ratio="1" contain style="max-height:200px;")
  //-               .text-body2 {{ $t('provide-constructive-feedback-to-help-innovators-develop-sustainable-solutions') }}
  //-   .row.q-mt-lg.q-mb-xl
  //-     .col-12
  //-       Topics(:data="messages.lead.topics")

  //- .bg-secondary.text-white(id="outcomes")
  //-   .row.items-center(style="position:relative").q-mb-xl
  //-     .lt-md.hero3a
  //-     .col-6.gt-sm
  //-       .hero3
  //-     .col-12.col-md-6  
  //-       .q-pb-xl
  //-         .q-pa-dynamic
  //-           h1 {{ $t('what-you-will-come-away-with') }}
  //-           ul.text-body1
  //-             li {{ $t('certificate-and-recognition-as-an-ifrc-network-innovation-lead') }}
  //-             li {{ $t('portfolio-of-practice-and-learnings') }}
  //-             li {{ $t('fully-trained-pool-of-innovation-in-your-region') }}
  //-             li {{ $t('access-to-a-global-network-of-innovators') }}
  //-     .hero-border

  //- div(style="margin-top:150px;margin-bottom:100px;" v-if="isOpen")
  //-   .row.items-top(id="apply")
  //-     .col-12.col-md-6  
  //-       .q-pa-dynamic
  //-         .text-h1 {{ $t('interesting-in-joining-the-lead-program') }}
  //-         .text-body1.q-my-lg {{ $t('staff-members-of-national-societies-and-volunteers-from-the-rcrc-network-whose-role-is-to-support-innovators-in-their-region') }}
  //-         .text-h2 {{ $t('program-timeline') }}
  //-         q-stepper(vertical flat dense value="-1" done-icon="none" header-class="myheader")
  //-           q-step(:title="$t('applications-close', [localiseDateExact('31/12/2023')])" :caption="localiseDateExact('31/12/2023')"  name="0" done)
  //-           q-step(:title="$t('theory-component')" :caption="localiseDate('01/01/2024')" name="1" done )
  //-           q-step(:title="$t('practical-component')" :caption="localiseDateRange('01/01/2024','01/10/2024')" name="2" done )
          
  //-     .col-6.gt-sm.self-top
  //-       q-img(src="/img/clock.webp" :ratio="1" contain style="max-height:400px;")

  //-   .row.q-mt-xl
  //-     .col-12.col-md-9
  //-       .q-ma-dynamic(v-if="isOpen")
  //-         .text-body2 {{ $t('register-your-interest-in-the-limitless-lead-program') }}
  //-         Signup.q-mt-lg(:type="'lead'")
  
  //- FAQ(id="faq" :data="messages.lead.faq").bg-lilac
</template>

<script>
  // import Signup from "../components/Signup.vue";
  // import FAQ from "../components/FAQ.vue";
  // import Topics from "../components/Topics.vue";
  import { debounce } from "quasar";
  import Breadcrumbs from "../components/Breadcrumbs.vue";
  import { DateTime } from "luxon";

  import { mapState, mapActions } from "vuex";

  export default {
    components: { Breadcrumbs },
    data: function() {
      return {
        snap: false,
        step: "t1",
        training: {
          t1: {
            videos: ["lead_mentoring_1.mp4", "lead_mentoring_2.mp4"],
          },
          t2: {
            videos: ["lead_innovation_1.mp4", "lead_innovation_2.mp4"],
          },
          t3: {
            videos: [
              "lead_ideation_1.mp4",
              "lead_ideation_2.mp4",
              "lead_ideation_3.mp4",
            ],
          },
          t4: {
            videos: ["lead_feedback_1.mp4", "lead_feedback_2.mp4"],
            extras: [{ name: "RISE Model of Feedback", path: "rise" }],
          },
          t5: {
            videos: ["lead_technology_1.mp4", "lead_technology_2.mp4"],
          },
          t6: {
            videos: [
              "lead_network_1.mp4",
              "lead_network_2.mp4",
              "lead_network_3.mp4",
            ],
          },
          t7: {
            videos: [
              "lead_evidence_1.mp4",
              "lead_evidence_2.mp4",
              "lead_evidence_3.mp4",
            ],
          },
          t8: {
            videos: ["lead_advocacy_1.mp4", "lead_advocacy_2.mp4"],
          },
          t9: {
            videos: ["lead_climate_1.mp4", "lead_climate_2.mp4"],
          },
        },
      };
    },
    computed: {
      ...mapState(["config"]),
      isOpen() {
        return process.env.VUE_APP_LEAD_APPLICATIONS_OPEN === "TRUE";
      },
      messages() {
        return this.$i18n.messages[this.$i18n.locale];
      },
    },
    async mounted() {
      await Promise.all([this.initConfig()]);
    },
    methods: {
      ...mapActions(["initConfig"]),
      getPathUrl(path) {
        if (!this.config) return "";

        let lang = "en";
        if (["en", "es", "fr", "ar"].includes(this.$i18n.locale))
          lang = this.$i18n.locale;
        return `${this.config.cloudfronturl}/assets/lead/${path}/${lang}.pdf`;
      },
      getOnePagerUrl(index) {
        if (!this.config) return "";
        let lang = "en";
        if (["en", "es", "fr", "ar"].includes(this.$i18n.locale))
          lang = this.$i18n.locale;
        return `${
          this.config.cloudfronturl
        }/assets/lead/onepagers/${lang}/${parseInt(index.slice(1))}.pdf`;
      },
      getVideoUrl(video) {
        if (!this.config) return "";
        // console.log(this.config);
        // return video;
        let lang = "en";
        if (["en", "es", "fr", "ar"].includes(this.$i18n.locale))
          lang = this.$i18n.locale;
        return `${this.config.cloudfronturl}/assets/lead/videos/${lang}/${video}`;
      },
      localiseDateExact(str) {
        let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
        return dt.toLocaleString({ ...DateTime.DATE_FULL });
      },
      localiseDate(str) {
        let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
        return dt.toLocaleString({ month: "long", year: "numeric" });
      },
      localiseDateRange(str1, str2) {
        let dt1 = DateTime.fromFormat(str1, "dd/MM/yyyy");
        let dt2 = DateTime.fromFormat(str2, "dd/MM/yyyy");

        return `${dt1.toLocaleString({
          month: "long",
        })} - ${dt2.toLocaleString({ month: "long", year: "numeric" })}`;
      },
      scrolled: debounce(function() {
        // console.log(this.$refs.toolbar.getBoundingClientRect());
        this.snap = this.$refs.toolbar.getBoundingClientRect().bottom < 70;
      }, 10),
    },
  };
</script>

<style lang="scss">
  @import "@/styles/quasar.variables.scss";
  .q-stepper__dot {
    // width: 8px !important;
    min-width: inherit !important;
    width: 16px !important;
    height: 16px !important;
    color: black;
    border-radius: 0 !important;
  }

  .q-stepper__dot:after,
  .q-stepper__dot:before {
    background-color: $accent !important;
    width: 4px !important;
    transform: translateX(-2px);
  }

  .q-stepper__title {
    font-size: 1.4rem !important;
    line-height: 1.7rem !important;
  }

  .q-stepper__caption {
    color: $secondary;
    font-size: 1rem !important;
    line-height: 1.6rem !important;
  }

  .q-tabs--vertical .q-tab {
    // color: red;
    text-align: left !important;
    display: block !important;
    white-space: inherit !important;
  }

  .q-tab__label {
    text-align: left !important;
  }
</style>

<style lang="scss" scoped>
  @import "@/styles/quasar.variables.scss";

  .hero1 {
    min-height: 700px;
    position: relative;
  }

  .hero2 {
    height: 100%;
    // background: rgb(21, 0, 36);
    background-image: url("/img/lead1.webp"),
      linear-gradient(285deg, #d9bad7 63%, $secondary 63.2%);
    // background-image: linear-gradient(285deg, #d9bad7 63%, $secondary 63.2%);
    background-repeat: no-repeat;
    background-position-y: bottom, center;
    background-position-x: right;
  }

  .hero3 {
    background-image: url("/img/lead2.webp");
    height: 100%;
    min-height: 40vw;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom -10px left -30px;
    // background-position-x: left;
  }

  .hero3a {
    background-image: url("/img/lead2.webp");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom -10px left -30px;
    opacity: 0.1;
    position: absolute;
    background-size: 100vw;
    bottom: 0;
    left: 0;
  }

  .hero-border {
    // border-bottom: $lilac 60px solid;
    background-color: $lilac;
    // background: url("~@/assets/img/lilacborder.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    bottom: -30px;
    height: 60px;
    // left: -1px;
    width: 100%;
    transform: skew(0, -0.5deg);
    overflow: hidden;
  }

  .hero2a {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    // background-color: red;
    background-image: url("/img/lead1.webp");
    opacity: 0.1;
    background-repeat: no-repeat;
    // background-position: right top;
    background-position: right -150px bottom 0px !important;
    // background-position-x: right30%;
  }

  .fixme {
    top: 70px;
    position: fixed;
    z-index: 1000;
    border-bottom: 1px solid white;
  }
</style>

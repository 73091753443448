import { render, staticRenderFns } from "./LeadTraining.vue?vue&type=template&id=5cf717c6&scoped=true&lang=pug&"
import script from "./LeadTraining.vue?vue&type=script&lang=js&"
export * from "./LeadTraining.vue?vue&type=script&lang=js&"
import style0 from "./LeadTraining.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./LeadTraining.vue?vue&type=style&index=1&id=5cf717c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf717c6",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QToolbar,QBtn,QTabs,QTab,QList,QItem,QTabPanels,QTabPanel,QVideo,QImg,QStepper,QStep});qInstall(component, 'directives', {Scroll});
